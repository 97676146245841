import React, { useContext, useEffect, useRef, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { useWindowScroll, useWindowSize } from "react-use";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageSetup } from "../components/context/page-setup";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { ProductDetails } from "../components/product/product-details";
import { FullWidthGallery } from "../components/images/full-width-gallery";

// Seo
import { PageSeo } from "../components/seo/page-seo";

// Utils
import { imageOrientation } from "../components/utils/image-orientation";
import { ConditionalWrapper } from "../components/utils/conditional-wrapper";
import { Video } from "../components/videos/video";

const Page = styled.div`
  @media (max-width: 768px) {
    margin: 0 0 20px 0;
  }

  & .module {
    margin: 0 0 20px 0;

    @media (max-width: 768px) {
      margin: 0 0 10px 0;
    }
  }

  & .promo-banner {
    padding: 0 20px;

    & p {
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.02em;
      text-align: center;
      margin: 0;
    }

    @media (max-width: 768px) {
      padding: 0 10px;

      & p {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }

  & .full-width-image {
    overflow: hidden;

    & .image-container,
    & img {
      max-height: 900px;
    }

    & .full-width-text-container {
      padding: 0 20px;

      @media (max-width: 768px) {
        padding: 0 10px;
      }
    }
  }

  // & .gatsby-image-wrapper {
  //   & img {
  //     transition: 500ms transform ease;
  //   }

  //   &:hover {
  //     & img {
  //       transform: scale(1.2);
  //     }
  //   }
  // }

  & .two-image-hero-product,
  & .single-hero-image,
  & .multiple-product-row,
  & .product-row,
  & .two-hero-products,
  & .two-product-row {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    & .product-row,
    & .multiple-product-row,
    & .two-hero-products,
    & .two-product-row {
      padding: 0 10px;
    }

    & .two-image-hero-product,
    & .single-hero-image {
      padding: 0;
    }
  }

  & .multiple-product-row,
  & .product-row {
    @media (max-width: 768px) {
      & .grid {
        display: flex;

        flex-direction: row;

        &.grid-3 {
          grid-template-columns: unset;
          // grid-column-gap: unset;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;

        & .single-product-thumbnail {
          min-width: 36vw;
          // margin: 0 10px 0 0;
          scroll-snap-align: center;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  & .two-image-hero-product {
    @media (max-width: 768px) {
      & .product-details-container {
        padding: 0 10px;
      }
    }
  }

  & .single-hero-image {
    & .layout-portrait {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      & a {
        display: block;
        grid-column: 2 / 4;
      }

      @media (max-width: 768px) {
        grid-column-gap: 10px;
        grid-template-columns: 1fr;

        & a {
          grid-column: 1;
        }
      }
    }

    @media (max-width: 768px) {
      & .product-details-container {
        padding: 0 10px;
      }
    }
  }

  & .grid {
    display: grid;
    grid-column-gap: 20px;

    @media (max-width: 768px) {
      grid-column-gap: 10px;
    }

    &.grid-1 {
    }

    &.grid-2 {
      grid-template-columns: 1fr 1fr;
    }

    &.grid-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.grid-4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 10px;
      }
    }
  }

  & .two-product-row {
    & .grid {
      &.outer-grid {
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
          grid-row-gap: 10px;
        }
      }
    }
  }
`;

const Index = ({ data }) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 768px)");

  // Page Setup
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageColor: `#fff`,
      pageType: `home`,
    });
  }, []);

  // Header color
  const { x, y } = useWindowScroll();
  const { width, height } = useWindowSize();
  const [triggerPoint, setTriggerPoint] = useState(10);
  const firstHeroImage = useRef(null);

  useEffect(() => {
    if (firstHeroImage.current !== null) {
      const dimensions = firstHeroImage.current.getBoundingClientRect();
      setTriggerPoint(dimensions.height);
    }
  }, [firstHeroImage, setTriggerPoint]);

  useEffect(() => {
    if (y >= triggerPoint) {
      setPageSetup({
        pageColor: `#fff`,
        pageType: `home active`,
      });
    } else {
      setPageSetup({
        pageColor: `#fff`,
        pageType: `home`,
      });
    }
  }, [y, triggerPoint, width, height]);

  const content = data.prismicPage.data.body.map((content, index) => {
    if (content.slice_type === "full_width_image") {
      return (
        <div
          className="module full-width-image"
          key={`single_module_${content.id}`}
          ref={index === 0 ? firstHeroImage : null}
        >
          <div className="image-container">
            {isDesktop ? (
              <GatsbyImage
                image={content.primary.image.gatsbyImageData}
                alt={
                  content.primary.image.alt !== null
                    ? content.primary.image.alt
                    : ""
                }
                draggable="false"
              />
            ) : (
              <GatsbyImage
                image={content.primary.mobileImage.gatsbyImageData}
                alt={
                  content.primary.mobileImage.alt !== null
                    ? content.primary.mobileImage.alt
                    : ""
                }
                draggable="false"
              />
            )}
          </div>
        </div>
      );
    }

    if (content.slice_type === "full_width_slideshow") {
      return (
        <div
          className="module full-width-image-slideshow"
          key={`single_module_${content.id}`}
          ref={index === 0 ? firstHeroImage : null}
        >
          <FullWidthGallery images={content.items} id={content.id} />
        </div>
      );
    }

    if (content.slice_type === "single_hero_product") {
      return (
        <div
          className="module single-hero-image"
          key={`single_module_${content.id}`}
        >
          <div
            className={`image-container layout-${imageOrientation(
              content.primary.product_thumbnail
            )}`}
          >
            <ConditionalWrapper
              condition={content.primary.us_product !== null}
              wrapper={(children) => (
                <Link to={`/products/${content.primary.us_product.handle}/`}>
                  {children}
                </Link>
              )}
            >
              <GatsbyImage
                image={content.primary.product_thumbnail.gatsbyImageData}
                alt={
                  content.primary.product_thumbnail.alt !== null
                    ? content.primary.product_thumbnail.alt
                    : ""
                }
                draggable="false"
              />

              {content.primary.us_product !== undefined &&
                content.primary.us_product !== null && (
                  <ProductDetails product={content.primary.us_product} />
                )}
            </ConditionalWrapper>
          </div>
        </div>
      );
    }

    if (content.slice_type === "two_image_hero_product") {
      return (
        <div
          className="module two-image-hero-product"
          key={`single_module_${content.id}`}
        >
          <div className="grid grid-2">
            <div className={`product-container`}>
              <ConditionalWrapper
                condition={content.primary.us_product !== null}
                wrapper={(children) => (
                  <Link to={`/products/${content.primary.us_product.handle}/`}>
                    {children}
                  </Link>
                )}
              >
                <GatsbyImage
                  image={content.primary.product_thumbnail_one.gatsbyImageData}
                  alt={
                    content.primary.product_thumbnail_one.alt !== null
                      ? content.primary.product_thumbnail_one.alt
                      : ""
                  }
                  draggable="false"
                />
              </ConditionalWrapper>
            </div>

            <div className={`product-container`}>
              <ConditionalWrapper
                condition={content.primary.us_product !== null}
                wrapper={(children) => (
                  <Link to={`/products/${content.primary.us_product.handle}/`}>
                    {children}
                  </Link>
                )}
              >
                <GatsbyImage
                  image={content.primary.product_thumbnail_two.gatsbyImageData}
                  alt={
                    content.primary.product_thumbnail_two.alt !== null
                      ? content.primary.product_thumbnail_two.alt
                      : ""
                  }
                  draggable="false"
                />
              </ConditionalWrapper>
            </div>
          </div>

          {content.primary.us_product !== undefined &&
            content.primary.us_product !== null && (
              <ProductDetails product={content.primary.us_product} />
            )}
        </div>
      );
    }

    if (content.slice_type === "two_hero_products") {
      return (
        <div
          className="module two-hero-products"
          key={`single_module_${content.id}`}
        >
          <div className="grid grid-2">
            <div className={`product-container`}>
              <ConditionalWrapper
                condition={content.primary.us_product_one !== null}
                wrapper={(children) => (
                  <Link
                    to={`/products/${content.primary.us_product_one.handle}/`}
                  >
                    {children}
                  </Link>
                )}
              >
                <GatsbyImage
                  image={content.primary.product_one_thumbnail.gatsbyImageData}
                  alt={
                    content.primary.product_one_thumbnail.alt !== null
                      ? content.primary.product_one_thumbnail.alt
                      : ""
                  }
                  draggable="false"
                />

                {content.primary.us_product_one !== undefined &&
                  content.primary.us_product_one !== null && (
                    <ProductDetails product={content.primary.us_product_one} />
                  )}
              </ConditionalWrapper>
            </div>

            <div className={`product-container`}>
              <ConditionalWrapper
                condition={content.primary.us_product_two !== null}
                wrapper={(children) => (
                  <Link
                    to={`/products/${content.primary.us_product_two.handle}/`}
                  >
                    {children}
                  </Link>
                )}
              >
                <GatsbyImage
                  image={content.primary.product_two_thumbnail.gatsbyImageData}
                  alt={
                    content.primary.product_two_thumbnail.alt !== null
                      ? content.primary.product_two_thumbnail.alt
                      : ""
                  }
                  draggable="false"
                />

                {content.primary.us_product_two !== undefined &&
                  content.primary.us_product_two !== null && (
                    <ProductDetails product={content.primary.us_product_two} />
                  )}
              </ConditionalWrapper>
            </div>
          </div>
        </div>
      );
    }

    if (content.slice_type === "two_product_row") {
      return (
        <div
          className="module two-product-row"
          key={`single_module_${content.id}`}
        >
          <div className={`grid grid-2 outer-grid`}>
            <div className="product-container product-one">
              <div className="image-container grid grid-2">
                <ConditionalWrapper
                  condition={content.primary.us_product_one !== null}
                  wrapper={(children) => (
                    <Link
                      to={`/products/${content.primary.us_product_one.handle}/`}
                    >
                      {children}
                    </Link>
                  )}
                >
                  <GatsbyImage
                    image={
                      content.primary.product_one_image_one.gatsbyImageData
                    }
                    alt={
                      content.primary.product_one_image_one.alt !== null
                        ? content.primary.product_one_image_one.alt
                        : ""
                    }
                    draggable="false"
                  />
                </ConditionalWrapper>

                <ConditionalWrapper
                  condition={content.primary.us_product_one !== null}
                  wrapper={(children) => (
                    <Link
                      to={`/products/${content.primary.us_product_one.handle}/`}
                    >
                      {children}
                    </Link>
                  )}
                >
                  <GatsbyImage
                    image={
                      content.primary.product_one_image_two.gatsbyImageData
                    }
                    alt={
                      content.primary.product_one_image_two.alt !== null
                        ? content.primary.product_one_image_two.alt
                        : ""
                    }
                    draggable="false"
                  />
                </ConditionalWrapper>
              </div>

              {content.primary.us_product_one !== undefined &&
                content.primary.us_product_one !== null && (
                  <ProductDetails product={content.primary.us_product_one} />
                )}
            </div>

            <div className="product-container product-two">
              <div className="image-container grid grid-2">
                <ConditionalWrapper
                  condition={content.primary.us_product_two !== null}
                  wrapper={(children) => (
                    <Link
                      to={`/products/${content.primary.us_product_two.handle}/`}
                    >
                      {children}
                    </Link>
                  )}
                >
                  <GatsbyImage
                    image={
                      content.primary.product_two_image_one.gatsbyImageData
                    }
                    alt={
                      content.primary.product_two_image_one.alt !== null
                        ? content.primary.product_two_image_one.alt
                        : ""
                    }
                    draggable="false"
                  />
                </ConditionalWrapper>

                <ConditionalWrapper
                  condition={content.primary.us_product_two !== null}
                  wrapper={(children) => (
                    <Link
                      to={`/products/${content.primary.us_product_two.handle}/`}
                    >
                      {children}
                    </Link>
                  )}
                >
                  <GatsbyImage
                    image={
                      content.primary.product_two_image_two.gatsbyImageData
                    }
                    alt={
                      content.primary.product_two_image_two.alt !== null
                        ? content.primary.product_two_image_two.alt
                        : ""
                    }
                    draggable="false"
                  />
                </ConditionalWrapper>
              </div>

              {content.primary.us_product_two !== undefined &&
                content.primary.us_product_two !== null && (
                  <ProductDetails product={content.primary.us_product_two} />
                )}
            </div>
          </div>
        </div>
      );
    }

    if (content.slice_type === "full_width_video") {
      return (
        <div
          className="module full-width-video"
          key={`single_module_${content.id}`}
        >
          <div className="video-container">
            <Video
              url={content.primary.video.embed_url}
              width={content.primary.video.width}
              height={content.primary.video.height}
            />
          </div>
        </div>
      );
    }

    if (content.slice_type === "single_product_row") {
      const products = content.items.map((product, innerIndex) => (
        <div
          className="single-product-thumbnail"
          key={`single_page_product_thumbnail_${innerIndex}_position_${index}`}
        >
          <div className="image-container">
            <ConditionalWrapper
              condition={content.primary.us_product !== null}
              wrapper={(children) => (
                <Link to={`/products/${content.primary.us_product.handle}/`}>
                  {children}
                </Link>
              )}
            >
              <GatsbyImage
                image={product.product_thumbnail.gatsbyImageData}
                alt={
                  product.product_thumbnail.alt !== null
                    ? product.product_thumbnail.alt
                    : ""
                }
                draggable="false"
              />
            </ConditionalWrapper>
          </div>
        </div>
      ));

      return (
        <div
          className={`module single product-row`}
          key={`single_module_${content.id}`}
        >
          <div className={`grid grid-${products.length}`}>{products}</div>

          {content.primary.us_product !== undefined &&
            content.primary.us_product !== null && (
              <ProductDetails product={content.primary.us_product} />
            )}
        </div>
      );
    }

    if (
      content.slice_type === "four_product_row" ||
      content.slice_type === "three_product_row"
    ) {
      const products = content.items.map((product, innerIndex) => (
        <div
          className="single-product-thumbnail"
          key={`single_page_product_${innerIndex}_position_${index}`}
        >
          <div className="image-container">
            <ConditionalWrapper
              condition={product.us_product !== null}
              wrapper={(children) => (
                <Link to={`/products/${product.us_product.handle}/`}>
                  {children}
                </Link>
              )}
            >
              <GatsbyImage
                image={product.product_thumbnail.gatsbyImageData}
                alt={
                  product.us_product !== null ? product.us_product.title : ""
                }
                draggable="false"
              />

              {product.us_product !== undefined &&
                product.us_product !== null && (
                  <ProductDetails product={product.us_product} />
                )}
            </ConditionalWrapper>
          </div>
        </div>
      ));

      return (
        <div
          className={`module multiple-product-row`}
          key={`single_module_${content.id}`}
        >
          <div className={`grid grid-${products.length}`}>{products}</div>
        </div>
      );
    }

    if (content.slice_type === "promo_banner") {
      return (
        <div
          className="module promo-banner"
          key={`single_module_${content.id}`}
        >
          <div className="text-container">
            <PrismicRichText field={content.primary.promo_banner_us.richText} />
          </div>
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo seoTitle={`SHANNON BOND`} seoText={null} seoImage={null} />
      <Page>{content}</Page>
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  {
    prismicPage(uid: { eq: "home" }) {
      _previewable
      data {
        title {
          text
        }
        body {
          ... on PrismicPageDataBodyPromoBanner {
            id
            slice_type
            primary {
              promo_banner_us {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyFullWidthImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 1.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  imgixParams: { q: 80 }
                )
                alt
              }
              mobileImage: image {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 1
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  imgixParams: { q: 80 }
                )
                alt
              }
            }
          }

          ... on PrismicPageDataBodyFullWidthSlideshow {
            id
            slice_type
            items {
              image {
                alt
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 1.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  imgixParams: { q: 90 }
                )
              }
            }
          }
          ... on PrismicPageDataBodySingleHeroProduct {
            id
            slice_type
            primary {
              us_product {
                handle
                id
                title
                admin_graphql_api_id
                variants {
                  price
                  compare_at_price
                }
              }
              product_thumbnail {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  imgixParams: { q: 90 }
                )
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageDataBodyTwoImageHeroProduct {
            id
            slice_type
            primary {
              us_product {
                handle
                id
                title
                admin_graphql_api_id
                variants {
                  price
                  compare_at_price
                }
              }
              product_thumbnail_one {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  imgixParams: { q: 90 }
                )
                alt
              }
              product_thumbnail_two {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  imgixParams: { q: 90 }
                )
                alt
              }
            }
          }
          ... on PrismicPageDataBodyTwoHeroProducts {
            id
            slice_type
            primary {
              us_product_one {
                handle
                id
                title
                admin_graphql_api_id
                variants {
                  price
                  compare_at_price
                }
              }
              us_product_two {
                handle
                id
                title
                admin_graphql_api_id
                variants {
                  price
                  compare_at_price
                }
              }
              product_two_thumbnail {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  imgixParams: { q: 90 }
                )
              }
              product_one_thumbnail {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  imgixParams: { q: 90 }
                )
              }
            }
          }
          ... on PrismicPageDataBodyTwoProductRow {
            id
            slice_type
            primary {
              us_product_one {
                handle
                id
                title
                admin_graphql_api_id
                variants {
                  price
                  compare_at_price
                }
              }
              us_product_two {
                handle
                id
                title
                admin_graphql_api_id
                variants {
                  price
                  compare_at_price
                }
              }
              product_one_image_one {
                alt
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  srcSetMaxWidth: 500
                  imgixParams: { q: 80 }
                )
              }
              product_one_image_two {
                alt
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  srcSetMaxWidth: 500
                  imgixParams: { q: 80 }
                )
              }
              product_two_image_one {
                alt
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  srcSetMaxWidth: 500
                  imgixParams: { q: 80 }
                )
              }
              product_two_image_two {
                alt
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  srcSetMaxWidth: 500
                  imgixParams: { q: 80 }
                )
              }
            }
          }
          ... on PrismicPageDataBodySingleProductRow {
            id
            slice_type
            primary {
              us_product {
                handle
                id
                title
                admin_graphql_api_id
                variants {
                  price
                  compare_at_price
                }
              }
            }
            items {
              product_thumbnail {
                alt
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  imgixParams: { q: 80 }
                )
              }
            }
          }
          ... on PrismicPageDataBodyThreeProductRow {
            id
            slice_type
            items {
              product_thumbnail {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  srcSetMaxWidth: 630
                  imgixParams: { q: 80 }
                )
              }
              us_product {
                handle
                id
                title
                admin_graphql_api_id
                variants {
                  price
                  compare_at_price
                }
              }
            }
          }

          ... on PrismicPageDataBodyFullWidthVideo {
            id
            slice_type
            primary {
              video {
                id
                embed_url
                width
                height
              }
            }
          }
          ... on PrismicPageDataBodyFourProductRow {
            id
            slice_type
            items {
              us_product {
                handle
                id
                title
                admin_graphql_api_id
                variants {
                  price
                  compare_at_price
                }
              }
              product_thumbnail {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 0.666
                  placeholder: NONE
                  backgroundColor: "#efefef"
                  srcSetMaxWidth: 500
                  imgixParams: { q: 80 }
                )
              }
            }
          }
        }
      }
    }
  }
`;
